<script>
export default {
  name: "AppLoading",
  props: {
    comment: {
      type: String,
      required: false,
    }
  }
}
</script>

<template>
  <div style="position:relative;width:100%;height:100vh;background:url('/img/loading/loading_bg.jpg') center;">
    <div style="position:absolute;top:30dvh;left:0;width:100%;">
      <div style="text-align:center;"><img src="/img/loading/loading.png" alt=""></div>
      <div style="margin-top:50px;text-align:center;line-height:0;">
        <div style="color:#fff;font-size:18px;line-height:25px;font-family: 'dotum', sans-serif;">{{ comment }}</div>
        <div><img src="/img/loading/loading_bar.svg" width="200" style="line-height:0;"></div>
      </div>
    </div>
  </div>
</template>